import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { paymentMethod } from '../Redux/LoginReduces';
import { Text, View } from '@react-pdf/renderer';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  maxHeight: "80vh",
  overflow: "auto",
  transform: 'translate(-50%, -50%)',
  width: { lg: "80%", md: "90%", sm: "90%", xs: "100%" },
  borderRadius: "10px",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
};




function InvoiceModal({ heading, text, headingTextStyle, textStyle, containerStyle, open, closeModal, buttonContainerStyle, buttonStyle, buttonStyle2, confirm, specialTextStyle, buttonText, specialText }) {


  let selectedInvoice = useSelector(
    (state) => state.loginReducer.selectedInvoice
  );
  const userData = useSelector((state) => state.loginReducer.updatedState);
  const vat = useSelector(state => state?.loginReducer?.vat)

  let otherDetails = { ...selectedInvoice }


  otherDetails.productDetails = selectedInvoice?.productDetails?.map((e, i) => {
    return {
      ...e,
      rate: selectedInvoice?.customerDetails[0]?.priceLevel[0]?.id == 1 ? e.trade_price :
        selectedInvoice?.customerDetails[0]?.priceLevel[0]?.id == 2 ? e?.warehouse_price : e?.retail_price
    }
  })



  return (
    <div  >
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[style, { containerStyle }]}>



          <Grid xs={12} container  >



            {otherDetails.vatAmount ? <Grid xs={12} sm={12} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} >

              <Typography sx={{ fontFamily: "Poppins", fontWeight: "bold", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "left" }} >
                {vat?.companyName}
              </Typography>
              <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "left" }} >
                {vat?.companyAddress}
              </Typography>

              <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "left" }} >
                {vat?.mobileNumber}
              </Typography>

              <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "left" }} >
                {vat?.companyEmail}
              </Typography>


              {otherDetails?.vatNumber && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                Vat Number : {vat?.vatNumber}

              </Typography>}


              {otherDetails?.companyNumber && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                Company Number : {vat?.companyNumber}

              </Typography>}



            </Grid> : ""}

            <Grid xs={8} sm={6} >

              {otherDetails?.customerDetails && otherDetails.customerDetails.length > 0 && otherDetails.customerDetails[0]?.accountManager && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "left" }} >
                {otherDetails.customerDetails[0]?.accountManager}

              </Typography>}

              {otherDetails.customerDetails && otherDetails.customerDetails?.length > 0 && otherDetails?.customerDetails[0]?.businessName && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "left" }} >
                Business Name: {otherDetails?.customerDetails[0]?.businessName}
              </Typography>}

              {otherDetails.customerDetails && otherDetails.customerDetails?.length > 0 && otherDetails?.customerDetails[0]?.accountNo && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "left" }} >
                Account Number: {otherDetails?.customerDetails[0]?.accountNo}
              </Typography>}

              <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "left" }} >
                Customer Name: {otherDetails.customerName}
              </Typography>



              {otherDetails?.customerDetails && otherDetails.customerDetails.length > 0 && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "left" }} >
                Customer Address: {otherDetails.customerDetails[0]?.address}
              </Typography>}

              {otherDetails?.customerType && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "left" }} >
                Customer Type: {otherDetails?.customerType}
              </Typography>}


              {otherDetails?.customerDetails && otherDetails.customerDetails.length > 0 && otherDetails.customerDetails[0]?.email && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "left" }} >
                Customer Email: {otherDetails?.customerDetails[0]?.email}
              </Typography>}
              {otherDetails?.customerDetails && otherDetails.customerDetails.length > 0 && otherDetails?.customerDetails[0]?.comment && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "left", color: "red" }} >
                Comments: {otherDetails?.customerDetails[0]?.comment}
              </Typography>}

              {/* {otherDetails?.customerDetails && otherDetails.customerDetails.length > 0 && (otherDetails?.customerDetails[0]?.address || otherDetails?.customerDetails[0]?.deliveryAddress) && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "left",color:"purple" }} >
                                Delivery Address: {otherDetails?.customerDetails[0]?.deliveryAddress || otherDetails?.customerDetails[0]?.address }
                            </Typography>} */}

            </Grid>


            <Grid xs={4} sm={6} >

              <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                Invoice #: {otherDetails.invoiceNumber}

              </Typography>


              <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                Date: {otherDetails.saleDate ? new Date(otherDetails.saleDate).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric"
                }) : otherDetails.saleReturnDate ?
                  new Date(otherDetails.saleReturnDate).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }) : otherDetails?.exchangeDate ? new Date(otherDetails.exchangeDate).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })
                    :
                    otherDetails?.claimDate ? new Date(otherDetails.claimDate).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })
                      :
                      new Date(otherDetails?.warrantyDate).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })

                }


              </Typography>
              <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                Time : {otherDetails.saleDate ? new Date(otherDetails.saleDate).toLocaleTimeString()
                  : otherDetails?.saleReturnDate ?
                    new Date(otherDetails.saleReturnDate).toLocaleTimeString()
                    : otherDetails?.exchangeDate ? new Date(otherDetails.exchangeDate).toLocaleTimeString() :
                      otherDetails?.claimDate ? new Date(otherDetails.claimDate).toLocaleTimeString()
                        :
                        new Date(otherDetails?.warrantyDate).toLocaleTimeString()


                }


              </Typography>

              {(otherDetails.returnInvoiceRef || otherDetails?.claimInvoiceRef || otherDetails?.warrantyInvoiceRef) && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                Invoice Ref #: {Array?.isArray(otherDetails?.returnInvoiceRef) ? otherDetails?.returnInvoiceRef?.map((e) => e) : otherDetails?.returnInvoiceRef}


              </Typography>}


              {/* {otherDetails?.invoiceRefDate && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                                Invoice Ref Date: {new Date(otherDetails.invoiceRefDate).toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                })}


                            </Typography>} */}
              {/* {otherDetails?.invoiceRefDate && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                                Invoice Ref Time : {new Date(otherDetails.invoiceRefDate).toLocaleTimeString()}


                            </Typography>} */}



              {otherDetails?.warrantyRefDate && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                Invoice Ref Date: {new Date(otherDetails.warrantyRefDate).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}


              </Typography>}
              {otherDetails?.warrantyRefDate && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                Invoice Ref Time : {new Date(otherDetails.warrantyRefDate).toLocaleTimeString()}


              </Typography>}



              {(otherDetails?.paymentMethod) && <Typography sx={{ color: Array.isArray(otherDetails?.paymentMethod) && otherDetails?.paymentMethod?.some((e) => e.toLowerCase() == "credit") ? Colors.red : Colors.green, fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                Payment Method : {Array.isArray(otherDetails.paymentMethod) ? otherDetails?.paymentMethod?.map((e, i) => e).join(",") : otherDetails?.paymentMethod}


              </Typography>}




              {otherDetails?.status && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                Status : {otherDetails?.status}


              </Typography>}

              {otherDetails?.reason && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right", color: Colors.red, fontWeight: "bold" }} >

                Reason : {otherDetails?.reason}


              </Typography>}


              {(Array.isArray(otherDetails?.paymentMethod) && otherDetails?.paymentMethod?.some((e, i) => e.toLowerCase() == "cheque") && !otherDetails.saleReturnDate) && <Box> <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                Cheque No : {otherDetails?.cheque_no}


              </Typography>

                <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                  Bank Name : {otherDetails?.bank_name}


                </Typography>

                <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                  Cheque Date : {new Date(otherDetails?.clear_date)?.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}


                </Typography>

              </Box>



              }


              {Array.isArray(otherDetails?.paymentMethod) && otherDetails?.paymentMethod?.some((e, i) => e.toLowerCase() == "online") && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                Reference Id : {otherDetails?.referenceId}

              </Typography>}


              {Array.isArray(otherDetails?.paymentMethod) && otherDetails?.paymentMethod?.some((e, i) => e.toLowerCase() == "card") && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                Transaction Id : {otherDetails?.transactionId}

              </Typography>}


              {Array.isArray(otherDetails?.paymentMethod) && otherDetails?.paymentMethod?.some((e, i) => e.toLowerCase() == "credit") && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                Credit Days : {otherDetails?.creditDays}

              </Typography>}

            </Grid>


            <Grid container xs={12} sx={{ marginTop: 2 }} >
              <Grid xs={5} >

                <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderBottom: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                  Product Name

                </Typography>

                {otherDetails.productDetails.map((e, i) => {

                  return (


                    <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                      {e.product_name}

                    </Typography>

                  )

                })}

                {otherDetails?.returnProductDetails && otherDetails?.returnProductDetails?.length > 0 && otherDetails?.returnProductDetails.map((e, i) => {

                  return (


                    <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                      {e.product_name}

                    </Typography>

                  )

                })}

              </Grid>
              <Grid xs={2} >

                <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderBottom: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                  IMEI Number

                </Typography>

                {otherDetails.productDetails.map((e, i) => {
                  console.log( e.IMEI, "iemi")
                  return (


                    // <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                    e?.IMEI != "" && typeof e?.IMEI == "object" ? <>
                    {e?.IMEI?.map((it)=>{
                      return(
                        <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >{it} </Typography>
                      )
                    })}
                      </> : <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >{e?.IMEI} </Typography>

                    // </Typography>

                  )

                })}

                {otherDetails?.returnProductDetails && otherDetails?.returnProductDetails?.length > 0 && otherDetails?.returnProductDetails.map((e, i) => {

                  return (


                    <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                      {e.product_name}

                    </Typography>

                  )

                })}

              </Grid>

              <Grid xs={1} >


                <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderBottom: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                  Quantity

                </Typography>

                {otherDetails.productDetails.map((e, i) => {

                  return (


                    <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                      {e.DamageQty ? e.DamageQty : e.saleQty}

                    </Typography>

                  )

                })}

                {otherDetails?.returnProductDetails && otherDetails?.returnProductDetails?.length > 0 && otherDetails.returnProductDetails.map((e, i) => {

                  return (


                    <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                      -{e.DamageQty ? e.DamageQty : e.saleQty}

                    </Typography>

                  )

                })}

              </Grid>

              <Grid xs={1} >


                <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderBottom: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                  Price

                </Typography>



                {otherDetails.productDetails.map((e, i) => {

                  return (


                    <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                      £{otherDetails?.saleReturnDate && "-"}

                      {e?.discountPrice > (otherDetails?.customerDetails[0]?.priceLevel[0]?.id ==
                        1 ? e?.trade_price : otherDetails?.customerDetails[0]?.priceLevel[0]?.id ==
                          2 ? e?.warehouse_price : e?.retail_price) ? e?.discountPrice : otherDetails?.customerDetails[0]?.priceLevel[0]?.id ==
                            1
                        ? Number(e.trade_price).toFixed(2)
                        : otherDetails?.customerDetails[0]?.priceLevel[0]
                          ?.id == 2
                          ? Number(e.warehouse_price).toFixed(2)
                          : Number(e.retail_price).toFixed(2)}
                    </Typography>

                  )

                })}


                {otherDetails?.returnProductDetails && otherDetails?.returnProductDetails?.length > 0 && otherDetails.returnProductDetails.map((e, i) => {

                  return (


                    <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                      £-{otherDetails?.customerDetails[0]?.accountManager == "A" ? Number(e?.trade_price).toFixed(2) : otherDetails?.customerDetails[0]?.accountManager == "B" ? Number(e?.warehouse_price).toFixed(2) : Number(e?.retail_price).toFixed(2)}

                    </Typography>

                  )

                })}

              </Grid>

              <Grid xs={1} >


                <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderBottom: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                  Discount

                </Typography>

                {otherDetails.productDetails.map((e, i) => {

                  return (


                    <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                      £
                      {(e.discountPrice < (otherDetails?.customerDetails[0]?.priceLevel[0]?.id ==
                        1 ? e?.trade_price : otherDetails?.customerDetails[0]?.priceLevel[0]?.id ==
                          2 ? e?.warehouse_price : e?.retail_price))
                        ? Number(
                          (otherDetails?.customerDetails[0]?.priceLevel[0]
                            ?.id == 1
                            ? e.trade_price
                            : otherDetails?.customerDetails[0]
                              ?.priceLevel[0]?.id == 2
                              ? e.warehouse_price
                              : e.retail_price) - e.discountPrice
                        ).toFixed(2)
                        : 0}
                    </Typography>

                  )

                })}

                {otherDetails?.returnProductDetails && otherDetails?.returnProductDetails?.length > 0 && otherDetails.returnProductDetails.map((e, i) => {

                  return (


                    <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                      £{e?.discountPrice ? (Number(otherDetails?.customerDetails[0]?.accountManager == "A" ? e?.trade_price : otherDetails?.customerDetails[0]?.accountManager == "B" ? e?.warehouse_price : e?.retail_price) - e?.discountPrice).toFixed(2) : 0}

                    </Typography>

                  )

                })}

              </Grid>


              <Grid xs={2} >


                <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderBottom: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                  Amount

                </Typography>

                {otherDetails.productDetails.map((e, i) => {

                  return (


                    <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                      £{e?.discountPrice ? Number(e?.discountPrice * (e.DamageQty ? e.DamageQty : e.saleQty)).toFixed(2) :
                        Number((otherDetails?.customerDetails[0]?.accountManager == "A" ? Number(e?.trade_price) : otherDetails?.customerDetails[0]?.accountManager == "B" ? e?.warehouse_price : e?.retail_price) * (e.DamageQty ? e.DamageQty : e.saleQty)).toFixed(2)}

                    </Typography>

                  )

                })}


                {otherDetails?.returnProductDetails && otherDetails?.returnProductDetails?.length > 0 && otherDetails.returnProductDetails.map((e, i) => {

                  return (


                    <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                      £-{e?.discountPrice ? Number(e?.discountPrice * (e.DamageQty ? e.DamageQty : e.saleQty)).toFixed(2) : (
                        Number((otherDetails?.customerDetails[0]?.accountManager == "A" ? e?.trade_price
                          : otherDetails?.customerDetails[0]?.accountManager == "B" ? e?.warehouse_price
                            : e?.retail_price) * (e.DamageQty ? e.DamageQty : e.saleQty))).toFixed(2)}

                    </Typography>

                  )

                })}

              </Grid>




            </Grid>
            <Grid container xs={12} sx={{ marginTop: 2 }} >

              <Grid xs={7} >



                <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderTop: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                  Subtotal

                </Typography>


              </Grid>

              <Grid xs={1} >



                <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderTop: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                  {otherDetails?.totalQty}

                </Typography>


              </Grid>

              <Grid xs={1} >



                <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderTop: `1px solid ${Colors.border}`, marginBottom: 2 }} >


                </Typography>


              </Grid>




              <Grid xs={1} >



                <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderTop: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                  £{otherDetails.discount ? Number(otherDetails.discount).toFixed(2) : 0}

                </Typography>


              </Grid>

              <Grid xs={2} >



                <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderTop: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                  £{Number(otherDetails.total - (otherDetails?.discount || 0)).toFixed(2)}

                </Typography>


              </Grid>



            </Grid>
            {otherDetails?.SDAmount != 0 && (
              <Grid container xs={12} sx={{}} >
                {/* <View
                // key={index}
                style={{
                  flexDirection: "row",
                  // borderWidth: 1,
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  borderColor: "#000",
                  borderTopWidth: 1,
                  padding: "5px 10px",
                }}
              > */}
                <Grid xs={10} >
                  <Text
                    style={{
                      fontFamily: "Poppins",
                      fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" },
                      fontWeight: "bold",
                    }}
                  >
                    Special Discount
                  </Text>
                </Grid>

                <Grid xs={2} >
                  <Text
                    style={{
                      fontFamily: "Poppins",
                      fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" },
                      fontWeight: "bold",
                    }}
                  >
                    - £ {otherDetails?.saleReturnDate && "-"}{Number(otherDetails?.SDAmount).toFixed(2)}

                    {/* - £{Number(printInvoiceData?.SDAmount).toFixed(2)} */}
                  </Text>
                </Grid>
                {/* </View> */}
              </Grid>
            )}
            <Grid container xs={12} sx={{ marginTop: 2 }} >

              <Grid xs={7} >



                <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderTop: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                  Total

                </Typography>


              </Grid>

              <Grid xs={1} >



                <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderTop: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                  {otherDetails?.totalQty}

                </Typography>


              </Grid>

              <Grid xs={1} >



                <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderTop: `1px solid ${Colors.border}`, marginBottom: 2 }} >


                </Typography>


              </Grid>




              <Grid xs={1} >



                <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderTop: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                  £{otherDetails.discount ? Number(otherDetails.discount).toFixed(2) : 0}

                </Typography>


              </Grid>

              <Grid xs={2} >



                <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderTop: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                  £{Number(otherDetails.total - (otherDetails?.discount || 0) - Number(otherDetails?.SDAmount)).toFixed(2)}

                </Typography>


              </Grid>



            </Grid>


            {otherDetails.vatAmount ?
              <Grid container xs={12} >

                <Grid item xs={9} >


                  <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", marginBottom: 2 }} >

                    VAT

                  </Typography>


                </Grid>


                <Grid item xs={2} >


                  <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", marginBottom: 2 }} >

                    £{Number(otherDetails.vatAmount).toFixed(2)}

                  </Typography>


                </Grid>

              </Grid> : ""}



            {otherDetails.vatAmount ?
              <Grid container xs={12} >

                <Grid item xs={9} sx={{ borderTop: `1px solid ${Colors.border}` }} >


                  <Typography sx={{ textAlign: "center", fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", marginBottom: 2, color: Colors.red }} >

                    Subtotal

                  </Typography>


                </Grid>


                <Grid item xs={3} sx={{ borderTop: `1px solid ${Colors.border}` }} >


                  <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", marginBottom: 2, color: Colors.red }} >

                    £{Number(otherDetails.subtotal).toFixed(2)}

                  </Typography>


                </Grid>

              </Grid> : ""}


          </Grid>


          <Box>

            <Typography style={{ fontSize: 15, fontWeight: "500", color: Colors.black, fontFamily: "Poppins" }} >This invoice has been served by <span style={{ fontSize: 16, fontWeight: "bold", color: Colors.red, fontFamily: "Poppins" }} >{otherDetails?.employeeDetails?.[0]?.fullName}</span></Typography>

          </Box>

          {userData?.role === "admin" || userData?.role_access?.some((e, i) => e.name.toLowerCase() == "showprofit" && e?.selected) && (
            <Typography style={{ fontSize: "18px", fontFamily: "Poppins", fontWeight: "bold" }}>
              {otherDetails?.saleReturnDate ? "Loss" :
                Number((otherDetails?.total - (otherDetails?.discount || 0) - otherDetails?.costPrice)) < 0 ?
                  "Loss" : "Profit"

              }: £{Math.abs(Number((otherDetails?.total - (otherDetails?.discount || 0)) - otherDetails?.costPrice)).toFixed(2)}
            </Typography>
          )}


          <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", ...buttonContainerStyle }} >

            <Button onClick={closeModal} sx={{
              backgroundColor: "#5DC002", color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "200px", fontSize: "14px", textTransform: "capitalize", mt: 1, fontFamily: "Poppins",
              '&:hover': {
                color: '#5DC002', // Set your desired hover color
              },
              ...buttonStyle
            }} >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default React.forwardRef(InvoiceModal)