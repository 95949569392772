import { Box, Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Colors } from "../../Constant/Color";
import CustomButton from "../../Components/Button";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import BasicSelect from "../../Components/Dropdown";
import { DataGrid } from "@mui/x-data-grid";
import Checkbox from "../../Components/checkbox";
import { CheckBox } from "@mui/icons-material";
import { Navigate, useNavigate } from "react-router-dom";
import AddDamageProductModal from "../../Components/addDamageProductModal";
import {
  updateDamageProducts,
  updateProducts,
  updateTrashProducts,
} from "../../Redux/LoginReduces";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Base_Uri } from "../../Constant/BaseUri";
import CustomModal from "../../Components/modal";
import DamageLedgerModal from "../../Components/DamageLedger";
import DamageReturnHistoryModal from "../../Components/DamageReturnHistoryModal";
import DamageTransferModal from "../../Components/damageTransferHistoryModal";
import { RingLoader } from "react-spinners";

function Damages({barcodeData,setBarcodeRead}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const damageProducts = useSelector(
    (state) => state.loginReducer.damageProducts
  );

  const [product, setProducts] = useState([]);

  const [gridKey, setGridKey] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("select");

  const [openLedgerModal, setOpenLedgerModal] = useState(false);
  const [openReturnHistoryModal, setOpenReturnHistoryModal] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);

  const [productClicked, setProductClicked] = useState([]);

  const [ledgerLoading, setLedgerLoading] = useState(false);

  const [damageProductModalVisible, setDamageProductModalVisible] =
    useState(false);

  const [addInInventoryModalVisible, setAddInInventoryModalVisible] =
    useState(false);
  const [addInTrashModalVisible, setAddInTrashModalVisible] = useState(false);
  const [quantityAddInInventory, setQuantityAddInInventory] = useState(0);
  const [quantityAddInTrash, setQuantityAddInTrash] = useState(0);
  const [loading, setLoading] = useState(false);

  const [searchProducts, setSearchProducts] = useState([]);

  const [category, setCategory] = useState([]);

  const selectProduct = (id) => {
    setProducts(
      product.map((e, i) => {
        if (e.id == id) {
          return {
            ...e,
            selected: !e?.selected,
          };
        } else {
          return e;
        }
      })
    );
  };

  const getDamageProducts = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .get(`${Base_Uri}getDamageProducts`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (data.status) {
            let myData = res.data;

            let { data } = myData;

            if (data && data.length > 0) {
              let allProducts = [];

              for (var i = 0; i < data.length; i++) {
                let product = data[i];

                product.id = i + 1;
                product["productName"] = product?.product_name;

                if (product?.status == "active") {
                  product.status = "Active";
                }

                allProducts.push(product);
              }

              setProducts(allProducts);
              setGridKey(gridKey + 1);
              dispatch(updateDamageProducts(allProducts));
            }
          }
        });
    }
  };

  const getProducts = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getProducts`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let myData = res.data;

        let { data } = myData;

        if (data && data.length > 0) {
          let allProducts = [];

          for (var i = 0; i < data.length; i++) {
            let product = data[i];

            product.id = i + 1;
            product["productName"] = product?.product_name;

            if (product.status == "active") {
              product.status = "Active";
            }

            allProducts.push(product);
          }

          updateProducts(allProducts);
        }
      })
      .catch((error) => {
        console.log(error, "error");
        // setLoading(false)
      });
  };

  const getTrashProducts = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getTrashProducts`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status) {
          dispatch(updateTrashProducts(data.data));
        }
      });
  };

  useEffect(() => {
    getDamageProducts();
  }, []);

  const column = [
    {
      field: "id",
      headerName: "ID",
      width: "50",
      headerClassName: "super-app-theme--header",
    },

    {
      field: "barcode",
      headerName: "Barcode",
      width: "150",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "product_name",
      headerName: "Product Name",
      headerClassName: "super-app-theme--header",
      width: "250",
    },
    {
      field: "category",
      headerName: "Category",
      headerClassName: "super-app-theme--header",
      width: "150",
    },
    {
      field: "model",
      headerName: "Model",
      headerClassName: "super-app-theme--header",
      width: "150",
    },

    {
      field: "DamageQty",
      headerClassName: "super-app-theme--header",
      headerName: "Quantity",
      width: "150",
    },
    {
      field: "cost_price",
      headerClassName: "super-app-theme--header",
      headerName: "Cost",

      width: "150",
    },
    {
      field: "retail_price",
      headerName: "Price",
      headerClassName: "super-app-theme--header",
      width: "150",
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: "150",
      renderCell: (cellValues) => {
        return (
          <p
            style={{
              color: Colors.red,
              fontFamily: "Poppins",
              fontWeight: "bold",
            }}
          >
            {"Faulty"}
          </p>
        );
      },
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   headerClassName: "super-app-theme--header",

    //   width: "150",
    //   renderCell: (cellValues) => {
    //     return (
    //       <Box
    //         style={{
    //           display: "flex",
    //           justifyContent: "space-between",
    //           alignItems: "center",
    //           width: "30%",
    //           backgroundColor: Colors.white,
    //         }}
    //       >
    //         <button
    //           onClick={() => navigate("/Home")}
    //           style={{
    //             padding: 0,
    //             margin: 0,
    //             backgroundColor: Colors.white,
    //             border: "0px",
    //           }}
    //         >
    //           <img
    //             src={require("../../Images/edit.png")}
    //             style={{ height: "20px", backgroundColor: Colors.white }}
    //           />
    //         </button>
    //         {/* <button style={{ padding: 0, margin: 0, border: "0px" }}  >
    //                 <img src={require("../../Images/dots.png")} style={{ height: "20px" }} />
    //             </button> */}
    //       </Box>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    let category =
      damageProducts &&
      damageProducts.length > 0 &&
      damageProducts.map((e, i) => {
        return e.category;
      });

    if (category) {
      category = [...new Set(category)];
      category.unshift("All Categories");
      setCategory(category);
    }
  }, [damageProducts?.length]);

  const handleSelectionModelChange = (selectionModel) => {
    



    if (selectionModel.length > 0) {

      selectionModel = selectionModel.length > 1 ? selectionModel[1] : selectionModel[0]

      let allProducts =
        damageProducts && damageProducts.length > 0
          ? damageProducts.map((e) => {
              if (selectionModel && selectionModel?.toString().includes(e.id)) {
                return {
                  ...e,
                  selected: true,
                };
              } else {
                return {
                  ...e,
                  selected: false,
                };
              }
            })
          : [];
      // setGridKey(gridKey + 1);
      dispatch(updateDamageProducts(allProducts));
    } else {
      let allProducts =
        damageProducts &&
        damageProducts?.length > 0 &&
        damageProducts.map((e, i) => {
          return {
            ...e,
            selected: false,
          };
        });
      // setGridKey(gridKey + 1);
      dispatch(updateDamageProducts(allProducts));
    }
  };

  const handleSearchProduct = (text) => {
    let search = text.target.value;

    if (!search && selectedCategory) {
      setSearchProducts(
        damageProducts &&
          damageProducts.length > 0 &&
          damageProducts.filter((e, i) => {
            return e.category == selectedCategory;
          })
      );

      return;
    }

    if (selectedCategory == "select" && !search) {
      setSearchProducts([]);
      return;
    }

    if (
      (selectedCategory == "All Categories" || selectedCategory == "select") &&
      search
    ) {
      setSearchProducts(
        damageProducts &&
          damageProducts.length > 0 &&
          damageProducts.filter((e, i) => {
            if (e.productName.toLowerCase().includes(search.toLowerCase()) || e?.barcode?.includes(search) ) {
              return e;
            }
          })
      );
      return;
    }

    if (
      selectedCategory !== "All Categories" &&
      selectedCategory !== "select"
    ) {
      let searching =
        damageProducts &&
        damageProducts.length > 0 &&
        damageProducts.filter((e, i) => {
          if (
            e.category == selectedCategory &&
            e.productName.toLowerCase().includes(search.toLowerCase() || e?.barcode?.includes(search) )
          ) {
            return e;
          }
        });

      if (searching.length > 0) {
        setSearchProducts(searching);
      }
    }
  };

  const handleAddInventory = async () => {
    if (!quantityAddInInventory) {
      alert("Kindly enter quantity");
      return;
    }

    let myProducts = [...damageProducts];

    let productDetail = myProducts.filter((e, i) => e.selected);

    if (Number(quantityAddInInventory) > Number(productDetail[0]?.DamageQty)) {
      alert("The given quantity exceed the available quantity");
      return;
    }

    productDetail =
      productDetail.length > 0 &&
      productDetail.map((e, i) => {
        return {
          ...e,
          DamageQty: quantityAddInInventory,
        };
      });

    setLoading(true);

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .post(`${Base_Uri}addDamageProductsInInventory`, productDetail[0], {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (!data.status) {
            alert(data.message);

            return;
          }

          alert(data.message);
          setGridKey(gridKey + 1);
          setLoading(false);
          setAddInInventoryModalVisible(false);
          setSearchProducts([]);
          getDamageProducts();
          getProducts();
        })
        .catch((error) => {
          alert(error.message);
          setLoading(false);
          return;
        });
    }
  };

  const handleAddTrash = async () => {
    if (!quantityAddInTrash) {
      alert("Kindly enter quantity");
      return;
    }

    let myProducts = [...damageProducts];

    let productDetail = myProducts.filter((e, i) => e.selected);

    if (Number(quantityAddInTrash) > Number(productDetail[0]?.DamageQty)) {
      alert("The given quantity exceed the available quantity");
      return;
    }

    productDetail =
      productDetail.length > 0 &&
      productDetail.map((e, i) => {
        return {
          ...e,
          DamageQty: quantityAddInTrash,
          qty: quantityAddInTrash,
        };
      });

    setLoading(true);
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .post(`${Base_Uri}addDamageProductsInTrash`, productDetail[0], {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (!data.status) {
            alert(data.message);
            setLoading(false);
            return;
          }

          alert(data.message);
          setGridKey(gridKey + 1);
          setLoading(false);
          setAddInTrashModalVisible(false);
          setSearchProducts([]);
          getDamageProducts();
          getTrashProducts();
        })
        .catch((error) => {
          alert(error.message);
          setLoading(false);
          return;
        });
    }
  };

  const handleFilterCategory = (name) => {
    setSelectedCategory(name);

    if (name == "All Categories") {
      setSearchProducts(damageProducts);
    } else {
      setSearchProducts(
        damageProducts &&
          damageProducts.length > 0 &&
          damageProducts.filter((e, i) => {
            if (e.category == name) {
              return e;
            }
          })
      );
    }
  };

  const handleOpenLedgerModal = () => {
    setLedgerLoading(true);

    let clickedProduct =
      damageProducts &&
      damageProducts.length > 0 &&
      damageProducts.filter((e, i) => e.selected);
    if (clickedProduct.length > 0) {
      clickedProduct = clickedProduct[0];
    }

    let barcode = clickedProduct?.barcode;

    axios
      .get(`${Base_Uri}getDamageProductLedger/${barcode}`)
      .then((res) => {
        let data = res.data;

        if (!data.status) {
          setLedgerLoading(false);
          alert(data?.message);
          return;
        }

        let ledgerData = data?.data;

        let dataToSend = { ...clickedProduct };
        dataToSend.productLedger = ledgerData;

        // clickedProduct.productLedger = ledgerData;

        setProductClicked(dataToSend);
        setLedgerLoading(false);
        setOpenLedgerModal(true);
      })
      .catch((error) => {
        alert(error?.message);
        setLedgerLoading(false);
        return;
      });
  };

  const handleOpenReturnHistoryModal = () => {
    let clickedProduct =
      damageProducts &&
      damageProducts.length > 0 &&
      damageProducts.filter((e, i) => e.selected);
    if (clickedProduct.length > 0) {
      clickedProduct = clickedProduct[0];
    }

    
    let barcode = clickedProduct?.barcode;

    

    setLedgerLoading(true)
    
    axios
      .get(`${Base_Uri}getDamageProductLedger/${barcode}`)
      .then((res) => {
        let data = res.data;

        if (!data.status) {
          setLedgerLoading(false);
          alert(data?.message);
          return;
        }

        let ledgerData = data?.data;

        let dataToSend = { ...clickedProduct };
        dataToSend.productLedger = ledgerData;

        // clickedProduct.productLedger = ledgerData;

        setProductClicked(dataToSend);
        setLedgerLoading(false);
        setOpenReturnHistoryModal(true);
      })
      .catch((error) => {
        alert(error?.message);
        setLedgerLoading(false);
        return;
      });
  };

  const handleOpenTransferHistoryModal = () => {
    let clickedProduct =
      damageProducts &&
      damageProducts.length > 0 &&
      damageProducts.filter((e, i) => e.selected);
    if (clickedProduct.length > 0) {
      clickedProduct = clickedProduct[0];
    }


    let barcode = clickedProduct?.barcode;

    setLedgerLoading(true)

    axios
      .get(`${Base_Uri}getDamageProductLedger/${barcode}`)
      .then((res) => {
        let data = res.data;


        if (!data.status) {
          setLedgerLoading(false);
          alert(data?.message);
          return;
        }

        let ledgerData = data?.data;

        let dataToSend = { ...clickedProduct };
        dataToSend.productLedger = ledgerData;

        // clickedProduct.productLedger = ledgerData;

        setProductClicked(dataToSend);
        setLedgerLoading(false);
        setOpenTransferModal(true);
      })
      .catch((error) => {
        alert(error?.message);
        setLedgerLoading(false);
        return;
      });
  };



  useEffect(() => {
    if (barcodeData?.length > 0 && !damageProductModalVisible) {

      let product =
        damageProducts &&
        damageProducts?.length > 0 &&
        damageProducts?.find((e) => e?.barcode == barcodeData);


      if (product) {
        setSearchProducts([product]);
        setBarcodeRead("");
      }
    }
  }, [barcodeData]);

  return ledgerLoading ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "70vh",
        width: "100%",
      }}
    >
      <RingLoader loading={ledgerLoading} size={100} color={Colors.black} />
    </Box>
  ) : (
    <Box
      sx={{ padding: "20px", backgroundColor: Colors.white, marginTop: "20px" }}
    >
      <Box sx={{ display: "flex" }}>
        <Grid container md={12} xs={12} sm={12}>
          <Grid item xs={12} sm={12} md={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Grid
                item
                xs={6}
                sm={4}
                md={5}
                lg={4}
                xl={3}
                style={{ marginRight: "5px" }}
              >
                <CustomButton
                  onClick={() => setDamageProductModalVisible(true)}
                  text="Add Damage Product"
                  style={{
                    width: "100%",
                    padding: "5px 5px 5px 5px",
                    fontSize: {
                      xl: "12px",
                      lg: "12px",
                      md: "10px",
                      sm: "10px",
                      xs: "8px",
                    },
                    display: "flex",
                    justifyContent: "space-around",
                    marginRight: "5px",
                    alignItems: "center",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
                md={5}
                lg={4}
                xl={3}
                style={{ marginRight: "5px" }}
              >
                <BasicSelect
                  value={selectedCategory}
                  status="Category"
                  id={category}
                  innerStyle={{
                    fontSize: "12px",
                    border: `1px solid ${Colors.border}`,
                    borderRadius: 10,
                    fontWeight: "bold",
                  }}
                  style={{ border: "0px" }}
                  onChange={(e) => handleFilterCategory(e)}
                />
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid md={11} xs={10} sm={10}>
                <Box
                  sx={{
                    width: "100%",
                    marginTop: { xs: "10px", sm: "10px", md: "0px" },
                    border: `1px solid ${Colors.button}`,
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "5px 10px 5px 10px",
                    margin: 0,
                  }}
                >
                  <TextField
                    onChange={handleSearchProduct}
                    variant="standard" // <== changed this
                    margin="normal"
                    required
                    fullWidth
                    InputProps={{
                      // startAdornment: <AccountCircle />, // <== adjusted this
                      disableUnderline: true,
                      fontSize: "12px",
                      // <== added this
                      // fontSize: "2px"
                    }}
                    placeholder="Search Name ,Id , Phone & Bar Code"
                    sx={{
                      width: "90%",
                      fontSize: "12px",
                      padding: 0,
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      margin: 0,
                    }}
                  />
                  <img
                    src={require("../../Images/search.png")}
                    style={{ height: "20px" }}
                  />
                </Box>
              </Grid>

              <Box
                sx={{
                  margin: 0,
                  height: "35px",
                  width: "35px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: Colors.button,
                }}
              >
                <img
                  src={require("../../Images/qrcode.png")}
                  style={{ height: "20px" }}
                />
              </Box>
            </Box>
          </Grid>

          {damageProducts &&
            damageProducts?.length > 0 &&
            damageProducts.filter((e, i) => e.selected).length == 1 && (
              <Box sx={{ display: "flex", mt: 1, ml: 1 }}>
                <Grid container md={12} xs={12} sm={12}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "80vw",
                      }}
                    >
                      <Grid
                        item
                        xs={3}
                        sm={2}
                        md={3}
                        lg={3}
                        xl={2.5}
                        style={{ marginRight: "5px" }}
                      >
                        <CustomButton
                          text={"Add In Inventory"}
                          onClick={() => setAddInInventoryModalVisible(true)}
                          style={{
                            width: "100%",
                            padding: "5px 5px 5px 5px",
                            fontSize: {
                              xl: "12px",
                              lg: "12px",
                              md: "10px",
                              sm: "10px",
                              xs: "8px",
                            },
                            display: "flex",
                            color: Colors.green,
                            justifyContent: "space-around",
                            marginRight: "5px",
                            alignItems: "center",
                            backgroundColor: Colors.white,
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={3}
                        md={4}
                        lg={5}
                        xl={2.5}
                        style={{ marginRight: "5px" }}
                      >
                        <CustomButton
                          text={"Add In bin"}
                          onClick={() => setAddInTrashModalVisible(true)}
                          style={{
                            width: "100%",
                            padding: "5px 5px 5px 5px",
                            fontSize: {
                              xl: "12px",
                              lg: "12px",
                              md: "10px",
                              sm: "10px",
                              xs: "8px",
                            },
                            display: "flex",
                            justifyContent: "space-around",
                            marginRight: "5px",
                            alignItems: "center",
                            backgroundColor: Colors.red,
                            color: Colors.white,
                            border: `1px solid green`,
                          }}
                        />
                      </Grid>
                    </Box>
                    <Box sx={{ display: "flex", width: "100%" }}>
                      <Grid
                        item
                        xs={4}
                        sm={3}
                        md={4}
                        lg={5}
                        xl={2.5}
                        style={{ marginRight: "5px", marginTop: "10px" }}
                      >
                        <CustomButton
                          text={"Show Ledger"}
                          onClick={() => handleOpenLedgerModal()}
                          style={{
                            width: "100%",
                            padding: "5px 5px 5px 5px",
                            fontSize: {
                              xl: "12px",
                              lg: "12px",
                              md: "10px",
                              sm: "10px",
                              xs: "8px",
                            },
                            display: "flex",
                            justifyContent: "space-around",
                            marginRight: "5px",
                            alignItems: "center",
                            backgroundColor: Colors.green,
                            color: Colors.white,
                            border: `1px solid green`,
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        sm={3}
                        md={4}
                        lg={5}
                        xl={2.5}
                        style={{ marginRight: "5px", marginTop: "10px" }}
                      >
                        <CustomButton
                          text={"Show Return History"}
                          onClick={() => handleOpenReturnHistoryModal()}
                          style={{
                            width: "100%",
                            padding: "5px 5px 5px 5px",
                            fontSize: {
                              xl: "12px",
                              lg: "12px",
                              md: "10px",
                              sm: "10px",
                              xs: "8px",
                            },
                            display: "flex",
                            justifyContent: "space-around",
                            marginRight: "5px",
                            alignItems: "center",
                            backgroundColor: Colors.button,
                            color: Colors.white,
                            border: `1px solid green`,
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        sm={3}
                        md={4}
                        lg={5}
                        xl={2.5}
                        style={{ marginRight: "5px", marginTop: "10px" }}
                      >
                        <CustomButton
                          text={"Show Transfer History"}
                          onClick={() => handleOpenTransferHistoryModal()}
                          style={{
                            width: "100%",
                            padding: "5px 5px 5px 5px",
                            fontSize: {
                              xl: "12px",
                              lg: "12px",
                              md: "10px",
                              sm: "10px",
                              xs: "8px",
                            },
                            display: "flex",
                            justifyContent: "space-around",
                            marginRight: "5px",
                            alignItems: "center",
                            backgroundColor: Colors.white,
                            color: Colors.green,
                            border: `1px solid green`,
                          }}
                        />
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
        </Grid>
      </Box>
      <Box sx={{ height: "500px", backgroundColor: "white", marginTop: 5 }}>
        {damageProducts && damageProducts?.length > 0 ? (
          <DataGrid
            key={gridKey}
            sx={{
              "& .MuiDataGrid-cell": {
                fontFamily: "Poppins",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
              },

              "& .MuiDataGrid-colCell, & .MuiDataGrid-colCellTitle": {
                padding: "0px", // Adjust this value as needed
              },
              "& .MuiDataGrid-columnHeaderCheckbox": {
                backgroundColor: Colors.black, // Change this to your desired checkbox background color
                color: "white", // Change this to your desired checkbox color
              },
              "& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root": {
                color: "gray",
              },
              "& .MuiDataGrid-columnHeader": {
                fontFamily: "Poppins",
                // color: "black",
                fontWeight: "bold",
              },
            }}
            checkboxSelection
            rowSelectionModel={damageProducts && damageProducts.length > 0 ? damageProducts.filter((e, i) => e?.selected)?.map((row) => row.id) : []}
            onRowSelectionModelChange={handleSelectionModelChange}
            rows={searchProducts.length > 0 ? searchProducts : damageProducts}
            columns={column}
          />
        ) : (
          ""
        )}
      </Box>

      <AddDamageProductModal
        heading={"Add Damage Product"}
        open={damageProductModalVisible}
        barcodeData = {damageProductModalVisible ?  barcodeData : ""}
        setBarcodeRead = {setBarcodeRead}
        buttonStyle2={{ width: "200px", fontSize: "14px", mt: 2 }}
        buttonText="Add Product"
        setProductArrangementModal={setDamageProductModalVisible}
        confirm={() => setDamageProductModalVisible(false)}
      />

      {addInInventoryModalVisible && (
        <CustomModal
          open={addInInventoryModalVisible}
          closeModal={() => setAddInInventoryModalVisible(false)}
          heading={"Add In Inventory"}
          credit={true}
          loading={loading}
          placeholder={"Enter Quantity"}
          onChange={(e) => setQuantityAddInInventory(e.target.value)}
          confirm={() => handleAddInventory()}
          text={"Are your sure you want to add this product in inventory."}
        />
      )}

      {addInTrashModalVisible && (
        <CustomModal
          open={addInTrashModalVisible}
          closeModal={() => setAddInTrashModalVisible(false)}
          heading={"Add In Trash"}
          credit={true}
          loading={loading}
          placeholder={"Enter Quantity"}
          onChange={(e) => setQuantityAddInTrash(e.target.value)}
          confirm={() => handleAddTrash()}
          text={"Are your sure you want to add this product in trash."}
        />
      )}

      {openLedgerModal && (
        <DamageLedgerModal
          data={productClicked}
          open={openLedgerModal}
          closeModal={() => setOpenLedgerModal(false)}
        />
      )}
      {openReturnHistoryModal && (
        <DamageReturnHistoryModal
          data={productClicked}
          open={openReturnHistoryModal}
          closeModal={() => setOpenReturnHistoryModal(false)}
        />
      )}
      {openTransferModal && (
        <DamageTransferModal
          data={productClicked}
          open={openTransferModal}
          closeModal={() => setOpenTransferModal(false)}
        />
      )}
    </Box>
  );
}

export default Damages;
